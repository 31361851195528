import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "Ynho";
const DEFAULT_CATEGORY = {
  all: true,
  original: false,
  piano: false,
  acoustic: false,
  electric: false,
  production: false,
  djing: false
};

const Ynho = () => {
  const [category, setCategory] = useState(DEFAULT_CATEGORY);

  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  const handleLinkClick = (link) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + link);
  };

  const handleButtonClick = (button) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + button);
  };

  const handleVideoClick = (video) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + video);
  };

  const handleCategoryClick = (category) => {
    if (category === "all") {
      setCategory({
        all: true,
        original: false,
        covers: false,
        piano: false,
        acoustic: false,
        electric: false,
        production: false,
        djing: false
      });
    } else if (category === "original") {
      setCategory({
        all: false,
        original: true,
        covers: false,
        piano: false,
        acoustic: false,
        electric: false,
        production: false,
        djing: false
      });
    } else if (category === "covers") {
      setCategory({
        all: false,
        original: false,
        covers: true,
        piano: false,
        acoustic: false,
        electric: false,
        production: false,
        djing: false
      });
    } else if (category === "piano") {
      setCategory({
        all: false,
        original: false,
        covers: false,
        piano: true,
        acoustic: false,
        electric: false,
        production: false,
        djing: false
      });
    } else if (category === "acoustic") {
      setCategory({
        all: false,
        original: false,
        covers: false,
        piano: false,
        acoustic: true,
        electric: false,
        production: false,
        djing: false
      });
    } else if (category === "electric") {
      setCategory({
        all: false,
        original: false,
        covers: false,
        piano: false,
        acoustic: false,
        electric: true,
        production: false,
        djing: false
      });
    } else if (category === "production") {
      setCategory({
        all: false,
        original: false,
        covers: false,
        piano: false,
        acoustic: false,
        electric: false,
        production: true,
        djing: false
      });
    } else if (category === "djing") {
      setCategory({
        all: false,
        original: false,
        covers: false,
        piano: false,
        acoustic: false,
        electric: false,
        production: false,
        djing: true
      });
    }
  };

  return (
    <>
      <div className="section">
        <div className="div-block">
          <h1 className="heading">ynho</h1>
          <p className="paragraph">
            brb crying in the club
          </p>
          <div className="div-block-2">
            <a
              href="mailto:josh@jclvsh.art"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/gmail.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("GMail")}
              />
            </a>
            <a
              href="https://open.spotify.com/artist/3I1G6pGlGEFefqQ10xIheC"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/spotify.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Spotify")}
              />
            </a>
            <a
              href="https://soundcloud.com/ynho_ai"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/soundcloud.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("SoundCloud")}
              />
            </a>
            <a
              href="https://bit.ly/sad-in-the-club"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/opensea.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("OpenSea")}
              />
            </a>
            <a
              href="https://www.instagram.com/ynho.ai/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/instagram.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Instagram")}
              />
            </a>
            <a
              href="https://www.tiktok.com/@ynho_ai"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/tiktok.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("TikTok")}
              />
            </a>
            <a
              href="https://www.youtube.com/@ynho_ai"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/youtube.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("YouTube")}
              />
            </a>
            {/*
              <a
                href="https://discord.com/invite/nM4VxWEaM2"
                className="w-inline-block"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="logos/discord.svg"
                  loading="lazy"
                  alt=""
                  className="image"
                  onClick={() => handleLinkClick("Discord")}
                />
              </a>
              <a
                href="https://t.me/+fVfqKUMN2bMyZTQx"
                className="w-inline-block"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="logos/telegram.svg"
                  loading="lazy"
                  alt=""
                  className="image"
                  onClick={() => handleLinkClick("Telegram")}
                />
              </a>
            */}
          </div>
        </div>
        <div className="div-block-3">
          <div className="buttons">
            <button
              id={category["all"] ? "all-clicked" : "all-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("all");
                handleButtonClick("all");
              }}
            >
              all
            </button>
            <button
              id={
                category["original"] ? "original-clicked" : "original-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("original");
                handleButtonClick("original");
              }}
            >
              original
            </button>
            <button
              id={
                category["covers"] ? "covers-clicked" : "covers-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("covers");
                handleButtonClick("covers");
              }}
            >
              covers
            </button>
            <button
              id={category["piano"] ? "piano-clicked" : "piano-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("piano");
                handleButtonClick("piano");
              }}
            >
              piano
            </button>
            <button
              id={
                category["acoustic"] ? "acoustic-clicked" : "acoustic-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("acoustic");
                handleButtonClick("acoustic");
              }}
            >
              acoustic
            </button>
            <button
              id={
                category["electric"] ? "electric-clicked" : "electric-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("electric");
                handleButtonClick("electric");
              }}
            >
              electric
            </button>
            <button
              id={
                category["production"]
                  ? "production-clicked"
                  : "production-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("production");
                handleButtonClick("production");
              }}
            >
              production
            </button>
            <button
              id={
                category["djing"]
                  ? "djing-clicked"
                  : "djing-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("djing");
                handleButtonClick("djing");
              }}
            >
              djing
            </button>
          </div>
          <div className="cards wrap">

            {/* riverside */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["acoustic"] ||
                  category["production"] ||
                  category["djing"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("riverside")}
            >
              <h1 className="heading">riverside</h1>
              <h3 className="heading">1.2.0</h3>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://linktr.ee/ynho"
                  target="_blank"
                  rel="noreferrer"
                >
                  ynho - riverside ft. HÜH
                </a>
              </p>
              <div className="empty"></div>
            </div>

            {/* if this love were mine */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["production"] ||
                  category["djing"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("if this love were mine")}
            >
              <h1 className="heading">if this love were mine</h1>
              <h3 className="heading">58.40</h3>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://soundcloud.com/ynho_ai/if-this-love-were-mine"
                  target="_blank"
                  rel="noreferrer"
                >
                  ynho - if this love were mine (luther x love by kendrick lamar)
                </a>
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/aEBYji00cws?si=XxReI37J4DS5RZvk"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
              </iframe>
            </div>

            {/* ifeelpurple */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["electric"] ||
                  category["production"] ||
                  category["djing"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("ifeelpurple")}
            >
              <h1 className="heading">ifeelpurple</h1>
              <h3 className="heading">53.36</h3>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://linktr.ee/ynho"
                  target="_blank"
                  rel="noreferrer"
                >
                  ynho - ifeelpurple ft. prince
                </a>
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/v81bqEHxtVw?si=tGTmUfvxHRbtnu2_"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
              </iframe>
            </div>

            {/* even cold november rain */}
            <div
              className={
                category["all"] ||
                  category["covers"] ||
                  category["electric"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("even cold november rain")}
            >
              <h1 className="heading">even cold november rain</h1>
              <h3 className="heading">53.03</h3>
              <p className="paragraph">november rain by guns n' roses second solo</p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/JT4EvxwOkas?si=_0nN_BcePW0zB4_V"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
              </iframe>
            </div>

            {/* nothing lasts forever */}
            <div
              className={
                category["all"] ||
                  category["covers"] ||
                  category["electric"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("nothing lasts forever")}
            >
              <h1 className="heading">nothing lasts forever</h1>
              <h3 className="heading">52.12</h3>
              <p className="paragraph">november rain by guns n' roses first solo</p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/cceox-RmGis?si=lxAm34V4MK23qq-a"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
              </iframe>
            </div>

            {/* isn't she lovely */}
            <div
              className={
                category["all"] ||
                  category["covers"] ||
                  category["acoustic"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("isn't she lovely")}
            >
              <h1 className="heading">isn't she lovely</h1>
              <h3 className="heading">48.58</h3>
              <p className="paragraph">isn't she lovely by stevie wonder</p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/xeNRoQBVIyU?si=qIpmuaVHem1ATc8t"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>

            {/* bando */}
            <div
              className={
                category["all"] ||
                  category["covers"] ||
                  category["electric"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("bando")}
            >
              <h1 className="heading">bando</h1>
              <h3 className="heading">47.36</h3>
              <p className="paragraph">bando by playboi carti</p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/3a_e7LZuhP4?si=YGMK3YoHh8ePehxi"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>

            {/* monsters */}
            <div
              className={
                category["all"] ||
                  category["covers"] || category["acoustic"]
                  ? "card"
                  : "hidden"
              }
              onClick={() =>
                handleVideoClick("monsters")
              }
            >
              <h1 className="heading">monsters</h1>
              <h3 className="heading">45.16</h3>
              <p className="paragraph">
                monsters by james blunt
              </p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://www.tiktok.com/@huh_joseph"
                  target="_blank"
                  rel="noreferrer"
                >
                  joseph huh / hüh
                </a>
                : vocals
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/q2Ow4E3uklI"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* blackbird */}
            <div
              className={
                category["all"] ||
                  category["covers"] || category["acoustic"]
                  ? "card"
                  : "hidden"
              }
              onClick={() =>
                handleVideoClick("blackbird")
              }
            >
              <h1 className="heading">blackbird</h1>
              <h3 className="heading">42.57</h3>
              <p className="paragraph">
                blackbird by the beatles
              </p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://www.tiktok.com/@huh_joseph"
                  target="_blank"
                  rel="noreferrer"
                >
                  joseph huh / hüh
                </a>
                : vocals
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/4yYRg4jBs-E"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* best part ft. chatgpt */}
            <div
              className={
                category["all"] ||
                  category["covers"] || category["acoustic"]
                  ? "card"
                  : "hidden"
              }
              onClick={() =>
                handleVideoClick("best part ft. chatgpt")
              }
            >
              <h1 className="heading">best part ft. chatgpt</h1>
              <h3 className="heading">39.00</h3>
              <p className="paragraph">
                best part by daniel caesar
              </p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://www.tiktok.com/@huh_joseph"
                  target="_blank"
                  rel="noreferrer"
                >
                  joseph huh / hüh
                </a>
                : vocals
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/_LdgRQbgJJE"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* wind tempos */}
            <div
              className={
                category["all"] ||
                  category["covers"] ||
                  category["piano"]
                  ? "card"
                  : "hidden"
              }
              onClick={() =>
                handleVideoClick("wind tempos")
              }
            >
              <h1 className="heading">wind tempos</h1>
              <h3 className="heading">38.00</h3>
              <p className="paragraph">
                wind tempos by porter robinson
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/we7WvXcQDDs"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* ynho - sad in the club (slowed and reverb) */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["production"]
                  ? "card"
                  : "hidden"
              }
              onClick={() =>
                handleVideoClick("sad in the club (slowed and reverb)")
              }
            >
              <h1 className="heading">ynho - sad in the club (slowed and reverb)</h1>
              <h3 className="heading">33.48</h3>
              <p className="paragraph">ynho - sad in the club (slowed and reverb)</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://bit.ly/sad-in-the-club"
                  target="_blank"
                  rel="noreferrer"
                >
                  nft available
                </a>
              </p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://open.spotify.com/artist/5nWUC0GyzOy79S9D7Tp02S"
                  target="_blank"
                  rel="noreferrer"
                >
                  job woo / ninexcix
                </a>
                : production
              </p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://eugeniechoiportfolio.weebly.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  eugenie choi
                </a>
                : animation
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/EjCfLkTQefk"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* ynho - sad in the club (original) */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["production"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("ynho - sad in the club (original)")}
            >
              <h1 className="heading">ynho - sad in the club (original)</h1>
              <h3 className="heading">30.14</h3>
              <p className="paragraph">ynho - sad in the club (original)</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://bit.ly/sad-in-the-club"
                  target="_blank"
                  rel="noreferrer"
                >
                  nft available
                </a>
              </p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://eugeniechoiportfolio.weebly.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  eugenie choi
                </a>
                : animation
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/twctwF7bFxU"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* ynho - 밀당 */}
            <div
              className={
                category["all"] || category["original"] || category["piano"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("ynho - 밀당")}
            >
              <h1 className="heading">ynho - 밀당</h1>
              <h3 className="heading">26.08</h3>
              <p className="paragraph">
                an original composed in my middle school sad boy days
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/3msPpwGqAO8"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* shr3d szn */}
            <div
              className={
                category["all"] || category["covers"] || category["electric"] ? "card" : "hidden"
              }
              onClick={() => handleVideoClick("shr3d szn")}
            >
              <h1 className="heading">shr3d szn</h1>
              <h3 className="heading">24.15</h3>
              <p className="paragraph">
                thunderstruck // eruption // flight of the wounded bumblebee
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/Vg9J_cgSM_8"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* boujee gala */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["production"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("boujee gala")}
            >
              <h1 className="heading">boujee gala</h1>
              <h3 className="heading">23.15</h3>
              <p className="paragraph">bad and boujee x met gala</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://open.spotify.com/artist/5nWUC0GyzOy79S9D7Tp02S"
                  target="_blank"
                  rel="noreferrer"
                >
                  job woo / ninexcix
                </a>
                : production
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/80k7Pcz3xLM"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* no rules */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["production"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("no rules")}
            >
              <h1 className="heading">no rules</h1>
              <h3 className="heading">22.15</h3>
              <p className="paragraph">no question x that's my rule</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://open.spotify.com/artist/5nWUC0GyzOy79S9D7Tp02S"
                  target="_blank"
                  rel="noreferrer"
                >
                  job woo / ninexcix
                </a>
                : production
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/IwPM8Z-IL04"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* xstarxgazingx */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["production"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("xstarxgazingx")}
            >
              <h1 className="heading">xstarxgazingx</h1>
              <h3 className="heading">20.42</h3>
              <p className="paragraph">stargazing x xxx.</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://open.spotify.com/artist/5nWUC0GyzOy79S9D7Tp02S"
                  target="_blank"
                  rel="noreferrer"
                >
                  job woo / ninexcix
                </a>
                : production
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/Ni5KFzT0ZGE"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* sicko dimension */}
            <div
              className={
                category["all"] ||
                  category["original"] ||
                  category["production"]
                  ? "card"
                  : "hidden"
              }
              onClick={() => handleVideoClick("sicko dimension")}
            >
              <h1 className="heading">sicko dimension</h1>
              <h3 className="heading">19.42</h3>
              <p className="paragraph">sicko mode x 4th dimension</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://open.spotify.com/artist/5nWUC0GyzOy79S9D7Tp02S"
                  target="_blank"
                  rel="noreferrer"
                >
                  job woo / ninexcix
                </a>
                : production
              </p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://eugeniechoiportfolio.weebly.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  eugenie choi
                </a>
                : cover
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/MwHYe3n-Bzo"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* runaway */}
            <div
              className={
                category["all"] || category["covers"] || category["electric"] ? "card" : "hidden"
              }
              onClick={() => handleVideoClick("runaway")}
            >
              <h1 className="heading">runaway</h1>
              <h3 className="heading">10.20</h3>
              <p className="paragraph">
                runaway by kanye west
              </p>
              <p className="paragraph">debuting the columbeatles x kmafia</p>
              <p className="paragraph">alex maddon: piano</p>
              <p className="paragraph">michael coiro: drums</p>
              <p className="paragraph">vincent le: sax outro</p>
              <p className="paragraph">james ma: cello</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://www.tiktok.com/@huh_joseph"
                  target="_blank"
                  rel="noreferrer"
                >
                  joseph huh / hüh
                </a>
                : pre-chorus and chorus
              </p>
              <p className="paragraph">kevin chang: verse 1</p>
              <p className="paragraph">
                <a
                  className="link"
                  href="https://open.spotify.com/artist/5nWUC0GyzOy79S9D7Tp02S"
                  target="_blank"
                  rel="noreferrer"
                >
                  job woo / ninexcix
                </a>
                : verse 2, production + mixing
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/WzqSHh2lUxE"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* LAKEHOUSE Medley */}
            <div
              className={
                category["all"] || category["covers"] || category["acoustic"] ? "card" : "hidden"
              }
              onClick={() => handleVideoClick("LAKEHOUSE Medley")}
            >
              <h1 className="heading">LAKEHOUSE Medley</h1>
              <h3 className="heading">0.0</h3>
              <p className="paragraph">
                photograph // i'm not the only one // tears in heaven
              </p>
              <iframe
                className="youtube"
                src="https://www.youtube.com/embed/5TQ3Hgztd2A"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ynho;
